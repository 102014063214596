:root {
  --primary-color: #354051;
  --secondary-color: #536175;
  --hover-color: #b5bbc2;
  --primary-white: #ffffff;
  --primary-table-alternate-row-color: #f2f2f2;
  --table-row-hover-color: #e6f7ff;
  --secondary-hover-color: #2b3540;
  --logo-height: 150px;
  --icon-height: 20px;
  --table-header-font: 18px;
  --primary-border-radius: 4px;
  --primary-input-button-height: 38px;
  --primary-container-height: 6vh;
  --secondary-container-height: 40px;
  --header-font: 18px;
  --primary-font: 16px;
  --secondary-font: 14px;
  --primary-gap: 10px;
  --primary-border-color: #c4c4c4;
  --button-width: 128px;
  --error-validation-clr: red;
  --xl-select-bar-width: 20vw;
  --xl-input-bar-width: 20vw;
  --large-select-bar-width: 30vw;
  --large-input-bar-width: 30vw;
  --medium-select-bar-width: 40vw;
  --medium-input-bar-width: 40vw;
  --small-select-bar-width: 100%;
  --small-input-bar-width: 100%;

}

.truncate-text {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.truncate-text:hover {
  display: inline-block;
  width: 200px;
  white-space: normal;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
}

.doc-label-height {
  height: 50px;
}

.homeSearchBar {
  position: relative;
  display: flex;
  width: 100%;
}

.homeSelectBar {
  width: 100%;
}

.topSectionScanBarIcon {
  position: absolute !important;
  font-size: 30px;
  top: 62% !important;
  transform: translateY(-50%);
  color: #888;
  cursor: pointer;
  padding: 0 !important;
  margin: 0px;
  height: 36px;
}

.homeSearchRadius {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
}

.buttonDimesions {
  border-width: 0px;
  height: var(--primary-input-button-height);
  background-color: var(--secondary-color);
  border-radius: var(--primary-border-radius);
  color: var(--primary-white);
  text-align: center;
  font-size: var(--primary-font);
  vertical-align: center;
  white-space: nowrap;
  width: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.performedby {
  text-align: right;
}

.single-screen-container {
  min-width: 100%;
  padding: 20px;
}

.medium-buttons {
  width: 140px;

}

.large-buttons {
  width: 180px;
}

.buttonDimesions.leftMargin {
  margin-left: 5px;
}

.performedby {
  text-align: right;
}

.buttonDimesionsLogin {
  text-align: center;
  align-items: center;
  border-width: 0px;
  height: var(--primary-input-button-height);
  background-color: var(--secondary-color);
  border-radius: var(--primary-border-radius);
  color: var(--primary-white);
  text-align: center;
  font-size: var(--primary-font);
  vertical-align: center;
  white-space: nowrap;
  width: 80px;
  justify-content: center;
  align-items: center;
}

.customeHeader {
  height: var(--secondary-container-height);
  font-size: var(--header-font);
  font-weight: bold;
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  vertical-align: center;
  margin-bottom: 8px;
  padding: 4px;
  color: var(--primary-white);
  position: sticky;
  top: 0;
  z-index: 1;
}

.main-div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.menu-div {
  display: flex;
  flex: 1;
}

.child-div {
  flex: 1;
  width: 85%;
  position: sticky;
  height: 95vh;
  position: fixed;
  left: 15%;
  padding: 14px;
  top: 5%;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  margin: 0px;
}

.pagination li {
  cursor: pointer;
  padding: 5px 10px;
}

.pagination .active {
  background-color: var(--primary-white);
  color: var(--primary-color);
  font-weight: var(--primary-font);
  border-radius: var(--primary-border-radius);
  padding: 5px 10px;
  margin-bottom: 1px;
}

.pagination .previous,
.pagination .next {
  padding: 5px 15px;
  font-weight: var(--primary-fo nt);
  cursor: pointer;
  overflow: hidden;
}

.input-label-login {
  font-size: var(--secondary-font);
  color: var(--primary-white);
  margin-bottom: 0px;
  text-align: left !important;
  width: 100%;
}

.input-label {
  font-size: var(--primary-font);
  margin-bottom: 0px;
  text-align: left !important;
  width: 100%;
  color: var(--primary-white);
}

.sidemenu-safechief-logo {
  height: var(--logo-height);
}

.sidemenu-checkout-icon {
  height: var(--icon-height);
}

.header {
  background-color: var(--primary-color);
  color: var(--primary-white);
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 5vh;
}

.sidemenu {
  width: 15%;
  position: fixed;
  top: 4.8%;
  height: 96%;
  background-color: var(--primary-color);
  color: var(--primary-white);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}

.sidemenu-item#active {
  background-color: var(--secondary-color);
  /* font-weight: bold; */
  border-radius: 5px;
}

.sidemenu.open {
  transform: translateX(0);
}

.brand-title {
  color: var(--primary-white) !important;
  font-size: x-large;
  font-weight: normal !important;
  margin-left: 10px;
  padding: 0px;

}

@media (max-width: 2560px) {
  .brand-title {
    margin-left: 105px;
  }

  @media(max-width: 1920px) {
    .brand-title {
      margin-left: 50px;
    }
  }

  @media(max-width: 1440px) {
    .brand-title {
      margin-left: 20px;
    }

    @media(max-width: 1024px) {
      .brand-title {
        margin-left: 0px;
        font-size: var(--header-font);
      }
    }
  }
}

@media (max-width: 768px) {
  .brand-title {
    font-size: 18px;
  }
}

.sidemenu-logo {
  margin: 20px 0;
}

.sidemenu-logo-img {
  width: 100px;
  height: auto;
}

.sidemenu-profile {
  text-align: center;
  margin-bottom: 20px;
}

.sidemenu-checkout-img {
  height: 20px;
}

.profile-name {
  font-size: 18px;
  font-weight: bold;
  word-break: auto-phrase;
}

.profile-designation {
  font-size: var(--secondary-font);
  color: var(--primary-white);
}

.sidemenu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.sidemenu-item {
  font-size: var(--primary-font);
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

.sidemenu-item i {
  font-size: var(--primary-font);
  color: #a0a0a0;
}

.sidemenu-item:hover,
.sidemenu-item.active {
  background-color: var(--secondary-hover-color);
}

.sidemenu-item i,
.sidemenu-item img,
.sidemenu-item svg,
.sidemenu-item .fa {
  width: 17px;
  font-size: 20px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: var(--primary-white);
}

.sidemenu-item img {
  object-fit: contain;
}

.store-managemnt {
  font-size: 18px !important;
}

.hamburger {
  display: none;
  color: var(--primary-white);
  background-color: var(--primary-color);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 19px;
  border-radius: 8px;
}

.hamburger-active {
  background-color: var(--secondary-color);
}

.container {
  max-width: 100% !important;
}

.primary-home-card-container {
  display: flex;
  justify-content: space-between;
  min-height: 36vh;
}

@media (max-width: 425px) {
  .primary-home-card-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  .primary-home-card {
    width: 100% !important;
  }
}

.primary-home-card {
  width: 48%;
  height: 100%;
  margin: 2px;
}

.primary-home-card-body {
  overflow-y: auto;
  padding: 8px;
  height: 30vh;
}

.header-text {
  font-size: var(--secondary-font)!important;
  font-weight: bold;
  white-space: nowrap;
}

.label-text {
  width: 58% !important;
  padding: 1ch 0ch;
  margin: 0px;
  text-align: left;
  font-size: var(--secondary-font);
  font-weight: bold;
  white-space: nowrap;
}

.label-text2 {
  font-size: var(--secondary-font);
  flex: 0.4;
  font-weight: bold;
  text-align: left;
  word-wrap: break-word;
  text-overflow: ellipsis;
  padding: 1ch;
}

.value-text {
  font-size: var(--secondary-font);
  flex: 0.6;
  word-wrap: break-word;
  padding: 1ch;
  margin: 0px;
}

.value-text2 {
  font-size: var(--secondary-font);
  flex: 0.6;
  word-wrap: break-word;
  padding: 1ch;
  margin: 0px;
}


@media (max-width: 768PX) {
  .label-text2 {
    flex: 0.38;
  }

  .value-text2 {
    flex: 0.62;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .label-text2 {
    flex: 0.5;
  }

  .value-text2 {
    flex: 0.5;
  }
}

.custom-label {
  color: #000 !important;
  font-weight: bold;
}

.home-view-more {
  text-align: right;
}

.password-input-container {
  position: relative;
  width: var(--xl-input-bar-width);
}

.password-input-container input {
  width: 100%;
  padding: 10px;
  padding-right: 35px;
  margin-top: 0px;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.password-input-container .eye-icon {
  position: absolute;
  right: 12px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
  font-size: 16px;
}

.password-input-container .eye-icon:hover {
  color: #000;
}

.forgot-password {
  margin-top: 5px;
  font-size: 12px;
  color: var(--primary-white);
  cursor: pointer;
  text-align: left;
}

.forgot-password:hover {
  text-decoration: underline;
}

.password_change_modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.password_change_modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2% !important;
}

@media (min-width: 375px) and (max-width: 767px) {
  .password_change_modal-content {
    max-width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .password_change_modal-content {
    max-width: 70%;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .password_change_modal-content {
    max-width: 40%;
  }
}

@media (min-width: 1400px) and (max-width: 2559px) {
  .password_change_modal-content {
    max-width: 35%;
  }
}

@media (min-width: 2560px) {
  .password_change_modal-content {
    max-width: 25%;
  }
}

.password_change_h2 {
  color: var(--secondary-color) !important;
}

.password_change_p {
  font-size: 14px !important;
  color: #555 !important;
}

.password_change_form {
  font-size: 15px !important;
  color: #333 !important;
}

.password_change_form_container {
  min-height: 300px;
}

.password_change_form_input {
  width: 100%;
  padding: 10px 2.5rem 10px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.password_input_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.password_input_wrapper i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
  transition: color 0.3s ease;
}

.password_input_wrapper i:hover {
  color: #333;
}

.password_change_form_group {
  text-align: left;
  width: auto !important;
}

@media (max-width: 1023px) {
  .hamburger {
    display: block;
  }

  .sidemenu {
    position: fixed;
    left: 0;
    width: 30%;
    background-color: var(--primary-color);
    color: var(--primary-white);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
  }

  .parentRadioDiv .form-check {
    margin-right: 100px;
  }

  .form-check-label {
    margin-left: 15px;
  }

  .sidemenu.open {
    transform: translateX(0);
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
  }

  .backdrop.visible {
    display: block;
  }

  .child-div {
    padding: 15px;
    overflow-y: auto;
    width: 100%;
    position: sticky;
    top: 5%
  }
}

@media (max-width: 599px) {
  .hamburger {
    display: block;
  }

  .sidemenu {
    position: fixed;
    left: 0;
    width: 40%;
    background-color: var(--primary-color);
    color: var(--primary-white);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
  }

  .sidemenu.open {
    transform: translateX(0);
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
  }

  .backdrop.visible {
    display: block;
  }

  .child-div {
    padding: 15px;
    overflow-y: auto;
    width: 100%;
    position: sticky;
    top: 5%
  }
}
.searchSelectBarWidth {
  width: 20vw;
}

@media (min-width: 1400px) and (max-width: 2560px) {
  .searchSelectBarWidth {
    width: 20vw;
  }

  .magnifierContainer {
    width: 3vw;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .searchSelectBarWidth {
    width: 25vw;
  }

  .magnifierContainer {
    width: 4vw;
  }
}

@media (min-width: 768px) and (max-width:1024px) {
  .searchSelectBarWidth {
    width: 30vw;
  }

  .magnifierContainer {
    width: 5vw;
  }
}

.magnifierSearchInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  box-sizing: border-box;
}

.customeBtn {
  white-space: nowrap;
  flex-shrink: 0;
  padding: 8px 12px;
}

.scanBarIconConatiner {
  position: relative;
}

.forceOpenAddendumScanIcon {
  right: 2%;
  top: 74% !important;
}

.refresh-button {
  background-color: var(--secondary-color);
  border: none;
  color: var(--primary-white);
  padding: 0px 12px;
  height: 38px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--primary-font);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.refresh-icon {
  font-size: var(--primary-font);

  color: var(--primary-white);
}

@keyframes spinAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.refresh-icon.spin {
  animation: spinAnimation 1s linear infinite;
}

.btn_styles {
  border-radius: 4px;
}

.primary-table-header {
  background-color: var(--secondary-color);
  color: var(--primary-white);
  position: sticky;
}

.primary.small-table-container {
  min-height: 32vh;
  max-height: 32vh;
}



.primary.medium-table-container {
  min-height: 72vh;
  max-height: 72vh;
  overflow-y: auto;
}

@media (max-width: 425px) {
  .primary.medium-table-container {
    min-height: 66vh;
    max-height: 66vh;
    overflow-y: auto;

  }
}

.primary.big-table-container {
  min-height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
}
.preopen-table {
  min-height: 60vh;
  max-height: 60vh;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .primary.big-table-container {
    min-height: 74vh;
    max-height: 74vh;
    overflow-y: auto;
  }
  .preopen-table {
    min-height: 45vh;
    max-height: 45vh;
    overflow-y: auto;
  }
}

@media (max-width: 425px) {
  .primary.big-table-container {
    min-height: 70vh !important;
    max-height: 70vh !important;
    overflow-y: auto;
  }
  .preopen-table {
    min-height: 40vh;
    max-height: 40vh;
    overflow-y: auto;
  }

  .bg-card.card-container {
    height: 80vh !important;
  }
}

.small-table {
  min-height: 30vh;
  max-height: 30vh;
  overflow-y: auto;
}

.primary.table-container {
  width: 100%;
  box-sizing: border-box;
}

.primary table {
  border-collapse: collapse;
  width: 100%;
}

.primary thead {
  position: sticky;
  white-space: nowrap;
  top: 0;
  height: var(--secondary-container-height);
  background-color: var(--secondary-color);
  color: var(--primary-white);
  z-index: 2;
  border-spacing: 0;
}

.primary table th:first-child {
  border-radius: 4px 0 0 4px;
}

.primary table th:last-child {
  border-radius: 0 4px 4px 0;
}

.primary th {
  font-weight: bold;
  background-color: var(--secondary-color);
  color: var(--primary-white);
  padding: 0.6rem 1rem;
  border-spacing: 0;
}

.primary th,
.primary td {
  padding: 0.6rem 1rem;
  text-align: left;
}

.primary tr {
  height: var(--secondary-container-height);
}

.primary th:first-child {
  box-shadow: none;
}

.primary tr:nth-child(even) {
  background-color: var(--primary-table-alternate-row-color);
}

.primary tbody tr:hover,
.primary tbody tr.selectedRow {
  background-color: var(--table-row-hover-color);

}
.homeOptionsContainer {
  height: var(--primary-container-height);
}

.otherOptionsContainer {
  height: var(--primary-container-height);
  display: flex;
  justify-content: end;
}

.minHeightTopSectionContainer {
  height: var(--primary-container-height);
  display: flex;
  justify-content: end;
  align-items: center;
}

.btn-gap {
  gap: 2%;
}

.icon {
  width: 30px;
  font-size: 24px;
}

.icon-background {
  align-items: center;
  color: var(--secondary-color);
  gap: 20px;
}

.icon-size {
  font-size: 30px;
}

@media (min-width: 320px) and (max-width: 425px) {

  .icon-size {
    font-size: 18px;
  }
  .icon-background{
    gap: 10px;
  }}

.fa-2x {
  font-size: 1.5em;
}

.note-text {
  font-size: 9px;
  text-align: right;
}

.select-input-container {
  width: 15%;
}

@media (max-width: 768px) {
  .select-input-container {
    width: 35%;
  }
}

.form-select:focus {
  box-shadow: none !important;
  border-color: #ccc !important;
  outline: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #ccc !important;
  outline: none !important;
}

.footer-card {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: var(--secondary-color);
  z-index: 1;
  height: var(--header-container-height);
}

.footer-text {
  color: var(--primary-white);
  font-size: var(--primary-font);
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.pagination-container {
  height: var(--header-container-height);
  width: auto;
  white-space: nowrap;
}

@media (min-width:100px) and (max-width: 425px) {
  .pagination-container {
    flex-direction: row !important;
    align-items: center;
    gap: 5px; 
    width: 100%;
  }

  .pagination-select {
    font-size: var(--primary-font);
    height: auto;
    width: 20px !important;
    padding:0px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 5px;
    height: 35px;
  }

  .pagination li {
    display: none;
  }

  .pagination li.active {
    display: block; 
    font-size: var(--primary-font);
    font-weight: 500;
  }

  .pagination li.previous,
  .pagination li.next {
    display: block; 
    padding: 0px !important;
    margin-top: 4px;
  }

  .footer-text {
    font-size: var(--secondary-font);
    white-space: nowrap; 
  }
}

.pagination-select {
  white-space: nowrap;
  width: auto;
  min-width: 60px;
  margin-left: 4px;
  margin-bottom: 2px;
  margin-top: 1px;
  padding: 4px 8px;
  font-size: var(--primary-font);
  border-radius: 4px;
  background-color: var(--primary-white);
  color: var(--primary-color);
}

.product-config-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.product-config-dropdown {
  display: none;
}

.module-authorization-card {
  border-radius: 10px;
  padding: 16px;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .product-config-tabs {
    display: none;
  }

  .product-config-dropdown {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 10px;
  }

  .dropdown-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #f9f9f9;
  }

  .dropdown-select:focus {
    outline: none;
    border-color: #181f27;
  }
}

.module-authorization-card {
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #cccccc;
}

.module-authorization-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #eeeeee;
  gap: 12px;
}

.module-authorization-row:last-child {
  border-bottom: none;
}

.module-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  flex: 1 1 50%;
}

.module-checkbox {
  margin: 0;
  cursor: pointer;
}

.module-name {
  font-size: 1rem;
  color: #444444;
  font-weight: 500;
  word-break: break-word;
}

.last-modified {
  flex: 1 1 50%;
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.last-modified-label {
  font-size: 0.875rem;
  color: #666666;
}

.last-modified-info {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.module-authorization-row:hover {
  background-color: #f5f5f5;
}

.module-checkbox:focus-visible,
.module-label:hover {
  outline: 2px solid #007bff;
}

@media (max-width: 768px) {
  .module-authorization-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .module-label {
    flex: 1 1 auto;
  }

  .last-modified {
    align-items: flex-start;
  }

  .module-name {
    font-size: 0.95rem;
  }

  .last-modified-label,
  .last-modified-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .module-authorization-card {
    padding: 12px;
  }

  .module-name {
    font-size: var(--secondary-font);
  }

  .last-modified-label,
  .last-modified-info {
    font-size: 0.75rem;
  }
}

.pagination .page-item .page-link {
  color: var(--primary-white);
  text-decoration: none;
}

.pagination .page-item .page-link:hover {
  color: var(--secondary-hover-color) !important;
  font-weight: 400;
}

.login-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  background-color: var(--primary-white);
  height: 100vh;
  width: 100%;
}

.login-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 90%;
  height: 78vh;
  background: var(--primary-color);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.login-logo {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.login-app-name {
  color: var(--primary-white);
  font-size: 42px;
  margin-top: 10px;
  font-weight: normal;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
}

.login-form {
  text-align: center;
  width: 65%;

}

.login-form h1 {
  margin-bottom: 20px;
  font-size: 42px;
  color: var(--primary-white);
}

.login-form h5 {
  margin-bottom: 20px;
  color: var(--primary-white);
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  width: 25%;
  margin-top: 25px;
  background-color: var(--secondary-color);
  border: none;
  padding: 10px 20px;
  color: var(--primary-white);
  border-radius: 5px;
  font-size: var(--primary-font);
  font-weight: bold;
  cursor: pointer;
  flex: 1;
}


.signin-button {
  position: relative;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: var(--secondary-color);
  border: none;
  color: var(--primary-white);
  font-size: var(--primary-font);
  font-weight: normal !important;
  cursor: pointer;
}

.signin-button {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.right-section {
  width: 400px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .signin-button {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 2560px) {

  .login-logo {
    width: 220px;
    height: auto;
  }

  .login-button {
    width: 40%;
    font-size: 12px;
  }

  .password-input-container {
    position: relative;
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {

  .login-logo {
    width: 200px;
    height: auto;
  }

  .login-button {
    width: 40%;
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .login-main-container {
    flex-direction: column;
  }

  .left-section,
  .right-section {
    width: 100%;
    padding: 20px;
    height: 100%;
  }

  .login-logo {
    width: 190px;
    height: auto;
  }

  .login-button {
    width: 40%;
    font-size: 12px;
  }

}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .login-main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .left-section {
    flex: none;
    width: 50%;
    height: 48vh;
    left: 25%;
    top: -10%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .login-form {
    width: 80%;
    text-align: center;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: 58%;
  }

  .login-button {
    width: 30%;
    position: relative;
    top: -20px;
  }

  .login-app-name {
    display: none;
  }

  .right-section {
    top: 62%;
  }

  .login-logo {
    width: 150px;
  }

  .signin-button {
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) and (min-width: 375px) {
  .login-main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .left-section {
    flex: none;
    width: 75%;
    height: 48vh;
    left: 13%;
    top: -10%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form {
    width: 80%;
    text-align: center;
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: 58%;
  }

  .login-form input {
    font-size: 12px;
    padding: 6px;
  }

  .login-button {
    width: 40%;
    font-size: 12px;
  }

  .login-app-name {
    display: none;
  }

  .login-form h1 {
    font-size: 25px !important;
  }

  .login-form h5 {
    font-size: 16px !important;
  }

  .right-section {
    top: 56%;
  }

  .right-section input {
    width: 300px;
    height: 35px;
  }

  .login-logo {
    width: 120px;
  }
}

.login-button:disabled {
  background-color: #ccc;
  color: var(--primary-color);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  .right-section {
    padding: 20px;
  }
}

.otp-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.otp-button {
  width: 100%;
  height: 42px;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  border: none;
  color: var(--primary-white);
  font-size: var(--primary-font);
  cursor: pointer;
}

@media (max-width: 768px) {
  .otp-button {
    height: 40px;
  }
}

@media (max-width: 480px) {
  .otp-buttons {
    width: 300px;
  }

  .otp-button {
    height: 35px;
    padding: 6px;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .otp-buttons {
    width: 250px;
  }

  .otp-button {
    height: 35px;
    padding: 6px;
  }
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 374px) and (min-width: 320px) {
  .login-main-container {
    padding: 10px;
  }

  .left-section {
    flex: none;
    width: 75%;
    height: 48vh;
    left: 13%;
    top: -10%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form {
    width: 80%;
    text-align: center;
    position: absolute;
    top: 62%;
    left: 51%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: 58%;
  }

  .login-form input {
    font-size: 10px;
    padding: 4px;
  }

  .login-button {
    width: 60%;
    font-size: 10px;
  }

  .login-form h1 {
    font-size: 20px !important;
  }

  .login-form h5 {
    font-size: 12px !important;
  }

  .right-section input {
    width: 250px;
    height: 35px;
  }

  .right-section {
    top: 53%;
  }

  .login-app-name {
    display: none;
  }

  .login-logo {
    width: 100px;
  }
}

.history-section {
  margin-top: 2%;
}

.forceOpen-history-details {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.history-details {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: auto;
}

.history-record {
  padding: 5px 0px !important;
  display: flex;
}

.history-label-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 35%;
  margin: 0px;
  font-size: var(--secondary-font);
  font-weight: bold;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .history-record {
    padding: 5px 0px !important;
    display: flex;
    flex-direction: column;
  }

  .value-text {
    padding: 5px 0px !important;
  }

}

.custom-file-upload {
  width: 900px;
  font-weight: 400;
  font-size: var(--secondary-font);
}

.filenameView .form-control {
  padding: 8px;
  border: none;
  font-size: 14px;
  flex: 1;
}

.upload-icon {
  font-size: 25px;
  color: var(--primary-white);
  padding: 5px;
  cursor: pointer;
  height: 35px;
  background-color: var(--secondary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

}

@media (max-width: 767px) {
  .custom-file-upload {
    font-size: 14px;
  }

  .upload-icon {
    font-size: 20px;
  }
}

@media (max-width: 1100px) {
  .custom-file-upload {
    width: 750px;
  }
}

@media (max-width: 900px) {
  .custom-file-upload {
    width: 550px;
  }
}

@media (min-width:375px) and (max-width:765px) {
  .custom-file-upload {
    width: 300px;
  }
}

@media (min-width:100px) and (max-width:374px) {
  .custom-file-upload {
    width: 200px;
  }
}

.history-container {
  height: 100%;
}

.long-text-area {
  border-width: 0px;
  height: 80px;
  width: 50%;
  overflow-y: auto;
  resize: none;
  padding-top: 25px !important;
  font-size: var(--primary-font);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.timeline {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.timeline::-webkit-scrollbar {
  display: none;
}

.container {
  overflow: hidden;
}

.home-event-timeline {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 17vh;
  display: flex;
  vertical-align: center;
  overflow: hidden;
}

.timeline li {
  transition: all 200ms ease-in;
}

.timestamp {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.status {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #6f7d95;
  position: relative;
  transition: all 200ms ease-in;
}

.status span {
  font-weight: 100;
  padding-top: 20px;
}

.status span:before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 18px;
  border: 2px solid #6f7d95;
  position: absolute;
  top: -11px;
  left: calc(50% - 12px);
  transition: all 200ms ease-in;
}

.image-circle {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50px;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  left: calc(50% - 28px);
  transition: all 200ms ease-in;
  text-align: center;
  display: block;
}

.image-circle:hover {
  border: 3px solid #6f7d95;
}

.icon-circle {
  margin-top: 1%;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 35px;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

@media (max-width: 1440px) {
  .icon-circle {
    margin-top: 2%;
  }
}

@media (max-width: 1024px) {
  .icon-circle {
    margin-top: 3%;
  }
}

@media (max-width: 425px) {
  .icon-circle {
    margin-top: 6%;
  }
}

.icon-circle:hover {
  border: 3px solid #6f7d95;
}

.swiper-slide {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.selected {
  border: 3px solid #15a4fa;
}

.bg-card {
  background: var(--primary-white);
  border-radius: var(--primary-border-radius);
}

.bg-card.card-container {
  height: 85vh;
}

.viewAllrecords-card-body {
  padding: 0 !important;
}

.bagupload-vh {
  height: 91vh;
}

@media (min-width: 2560px) {
  .rolesCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    height: 45vh;
    overflow-y: auto;
  }
}

@media (min-width: 1400px) {
  .rolesCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    height: 45vh;
    overflow-y: auto;
  }
}

@media (min-width: 1024px) and (max-width:1400px) {
  .rolesCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    height: 25vh;
    overflow-y: auto;
  }
}

@media (min-width: 768px) and (max-width:1023px) {
  .rolesCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    height: 70vh;
    overflow-y: auto;
  }
}

@media (max-width: 425px) {
  .rolesCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    height: 60vh;
    overflow-y: auto;
  }
}

.document-container {
  margin-top: 20px;
  border: 1px solid #ced4da;
  border-radius: var(--primary-border-radius);
  padding: 20px;
}

.viewDocs {
  display: grid;
  gap: 20px;
  height: 40vh;
  overflow-y: auto;
  margin-bottom: 0%;
}

.iconsContainerPosition {
  position: relative;
}

.cross-icon {
  position: absolute;
  color: var(--secondary-hover-color);
}

.cross-icon.pics {
  top: 2%;
  left: 92%;
}

.cross-icon.pics {
  top: 2%;
  left: 92%;
}

.cross-icon.addendumpics {
  top: 0%;
  left: 72%;
}

.cross-icon.docs {
  top: 1%;
  left: 92%;
}

.viewDocs.width {
  width: 35vw;
}

.docPicDimensions {
  width: 45%;
}

.docContainer {
  display: flex;
  justify-content: space-between
}

.docSection {
  padding-top: 5%;

}

@media (min-width: 375px) and (max-width: 767px) {
  .viewDocs {
    grid-template-columns: repeat(1, 1fr);
    height: 26vh;
  }

  .docSection {
    padding-top: 30%;
    width: 100%;
  }

  .docContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .viewDocs {
    grid-template-columns: repeat(2, 1fr);
    height: 26vh;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .viewDocs {
    grid-template-columns: repeat(3, 1fr);
    height: 26vh;
  }
}

@media (min-width: 1400px) and (max-width: 2559px) {
  .viewDocs {
    grid-template-columns: repeat(4, 1fr);
    height: 25vh;
  }
}

@media (min-width: 2560px) {
  .viewDocs {
    grid-template-columns: repeat(8, 1fr);
    height: 20vh;
  }
}

@media (min-width: 2560px) {

  .viewReportsCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    overflow-y: auto;
  }
}

@media (min-width: 1400px) {
  .viewReportsCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    overflow-y: auto;
  }
}

@media (min-width: 1024px) and (max-width:1400px) {
  .viewReportsCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    overflow-y: auto;
  }
}

@media (min-width: 768px) and (max-width:1023px) {
  .viewReportsCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    overflow-y: auto;
  }
}

@media (max-width: 425px) {
  .viewReportsCheckBoxcontainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    height: 25vh;
    overflow-y: auto;
  }

  .viewReportsCheckBoxcontainer2{
    height: 68vh;
  }
}

.rolesSelect {
  width: 25%;
  margin-top: 10px;
}

@media (max-width: 1224px) {
  .viewReportsCheckBoxcontainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1036px) {
  .viewReportsCheckBoxcontainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 620px) {
  .viewReportsCheckBoxcontainer {
    grid-template-columns: 1fr;
  }

  .rolesSelect {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .rolesSelect {
    width: 60%;
  }
}

@media (min-width: 629px) and (max-width: 1000px) {
  .rolesSelect {
    width: 40%;
  }
}

.spinner {
  top: 55%;
  left: 50%;
  width: 3rem;
  height: 3rem;
  border: 4px solid #e0e0e0;
  border-top: 4px solid #15a4fa;
  border-radius: 50%;
}

.loader-container {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: var(--table-header-font);
}
.timeline-loader{
 top:48%;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .timeline-loader {
    top: 56%;
  }
}

.approval-loader {
  position: fixed;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  font-size: var(--table-header-font);
}

.no-records {
  position: fixed;
  top: 57%;
  left: 58%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  font-size: 18px;
  color: #555;
}
@media (min-width: 320px) and (max-width: 1023px) {
  .no-records {
    left: 50%;

  }
}

.no-timeline {
  margin-top: 60px !important;
}

.nav-link.active-tab {
  background-color: #536175;
  color: white;
  margin-bottom: 5px;
}

.nav-link {
  color: var(--secondary-color);
}

.buttonSpaceAlignment {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.buttonCntrAlignment {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: var(--primary-gap);
  margin-top: 8px;
}

.buttonApproval {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.addendum-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  background-color: rgba(112, 111, 111, 0.3); 
 justify-content: center;
  align-items: center;
  z-index: 1000;
}

.buttonEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px;
}

.spinner-border {
  width: 40px;
  height: 40px;
  border-width: 0.3rem;
}

.change-password-modal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: rgba(29, 29, 29, 0.3);
}

.sidemenu.blurred {
  filter: blur(5px);
  pointer-events: none;
}

.change-password-container {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 45%;
  width: 30%;
  overflow-y: auto;
  padding: 15px;
  color: var(--bs-card-color);
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .change-password-container {
    height: auto;
    width: 25%;
    overflow-y: hidden;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .change-password-container {
    height: auto;
    width: 35%;
    overflow-y: hidden;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .change-password-container {
    height: auto;
    width: 45%;
    overflow-y: hidden;
  }
}

@media (max-width: 767px) {
  .change-password-container {
    height: auto;
    width: 60%;
    overflow-y: hidden;
  }
}

@media (max-width: 480px) {
  .change-password-container {
    height: auto;
    width: 70%;
    overflow-y: hidden;
  }
}

@media (max-width: 768px) {
  .change-password-container {
    width: 60%;
    padding: 15px;
  }
}

@media (max-width: 425px) {
  .change-password-container {
    width: 85%;
    padding: 15px;
  }
}

.change-password-heading {
  text-align: center;
  font-weight: bold;
  color: var(--bs-card-color);
  font-size: var(--header-font);
}

.pcard {
  padding: 10px;
}

.change-password-form {
  display: flex;
  flex-direction: column;
  color: var(--bs-card-color);
  font-size: var(--primary-font);
}

.change-password-form .form-control {
  color: var(--bs-card-color) !important;
}

.change-password-form .form-control::placeholder {
  color: var(--bs-card-color);
  font-weight: 200;
  opacity: 1;
  font-size: var(--secondary-font);
}

.pform-group {
  position: relative;
}

.pform-group .form-control {
  padding-right: 40px;
}

.fa-eye,
.fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--bs-card-color);
}

.error-validation {
  position: absolute;
  bottom: -18px;
  left: 0;
  color: var(--error-validation-clr);
  font-size: 0.8rem;
}


/* .form-group {
  margin-bottom: 2.5rem;
  bottom: -18px;
  left: 0;
  font-size: 0.8rem;
}

.form-group {
  margin-bottom: 2.5rem;
} */

.signin-validation {
  color: var(--error-validation-clr);
  font-size: var(--primary-font);
}

.forceOpenContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  gap: 8px;
  padding: 10px;
  align-items: end;
}

.sectionBorder {
  padding: 20px;
  margin-top: 0%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: var(--primary-border-color);
}

.sectionBorder2 {
  padding: 20px;
  margin-top: 5%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: var(--primary-border-color);
}

.gap {
  gap: 10px
}

.error-message-validation {
  color: var(--error-validation-clr);
  font-size: var(--secondary-font);
}

.forceOpenScrollContainer {
  height: 70vh;
  overflow-y: auto;
}

.home-scroll {
  overflow-y: auto;
}

.singleScreenContainer {
  height: auto;
  overflow-y: auto;
}

.singleScreenContainer3 {
  height: 60vh;
  overflow-y: auto;
}
.search-result-panel {
  height: 55vh;
  background-color: var(--primary-white);
  box-shadow: #2b3540;
  overflow-y: auto;
  width: auto;
}

.search-container {
  position: relative;
  width: 100%;
}

.search-result-panel {
  position: absolute;
  z-index: 1000;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  margin-right: 15px;
  margin-top: 0px !important;
}

.viewDocs,
.singleScreenContainer,
.home-scroll,
.search-result-panel,
.forceOpenScrollContainer,
.small-table,
.primary.medium-table-container,
.primary.big-table-container,
.preopen-table,
.primary-home-card-body {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
  -ms-overflow-style: auto;
  -webkit-overflow-scrolling: touch;
}
.bg-card.card-container2 {
  height: 85vh !important;
}
.viewDocs,
.singleScreenContainer,
.home-scroll,
.search-result-panel,
.forceOpenScrollContainer,
.small-table,
.primary.medium-table-container,
.primary.big-table-container,
.preopen-table,
.primary-home-card-body {
  -webkit-scrollbar: 4px;
  -webkit-scrollbar-track: #f1f1f1;
  -webkit-scrollbar-thumb: #888;
}

.homeSearchButton {
  border-width: 0px;
  height: var(--primary-input-button-height);
  background-color: var(--secondary-color);
  border-radius: var(--primary-border-radius);
  color: var(--primary-white);
  text-align: center;
  vertical-align: center;
  padding: 0px 14px;
  white-space: nowrap;
  align-items: center;
}

.searchBarsAlignment {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.searchField {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.searchContainerBlock {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}

@media (max-width: 425px) {
  .searchContainerBlock {
    order: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
}

.select-input-container {
  width: 25vw;
  height: 38px;

}

.searchBarsAlignmentHome {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
}

.btnsAlignment-topSection {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: var(--primary-gap);
}

@media (max-width: 425px) {
  .searchBarsAlignmentHome {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    gap: 5px;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .searchField {
    display: flex;
    max-width: 100vw !important;
    position: relative;
  }

  .homeSearchButton {
    border-width: 0px;
    height: var(--primary-input-button-height);
    background-color: var(--secondary-color);
    border-radius: var(--primary-border-radius);
    color: var(--primary-white);
    text-align: center;
    vertical-align: center;
    padding: 0px 14px;
    white-space: nowrap;
    margin-top: 2%;
    width: 93vw;
  }

  .homeOptionsContainer {
    height: 12vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .otherOptionsContainer {
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .select-input-container {
    width: 85vw;
    height: 38px;
  }
}

.magnifierSearchInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  box-sizing: border-box;
}

.customeBtn {
  white-space: nowrap;
  flex-shrink: 0;
  padding: 8px 12px;
}

.searchField {
  display: flex;
  max-width: 20vw;
  position: relative;
}

.scanBarIcon {
  position: absolute !important;
  font-size: 30px;
  top: 62% !important;
  transform: translateY(-50%);
  color: #888;
  cursor: pointer;
  padding: 0 !important;
  margin: 0px;
  height: 36px;
}

.MagnifierscanBarIcon {
  right: 15%;
}

.NonMagnifierscanBarIcon {
  right: 4%;
}

.magnifierContainer {
  width: 15%;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-width: 0px;
  background-color: var(--secondary-color);
  color: var(--primary-white);
  height: 38px;
  margin-left: -3px;
  z-index: 1;
}

@media (max-width: 1440px) {

  .searchField {
    max-width: 20vw;
  }
}

@media (max-width: 1024px) {
  .searchField {
    max-width: 30vw;
  }
}

@media (max-width: 768px) {
  .searchField {
    max-width: 40vw;
  }
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  margin-top: 3px;
}

input[type="checkbox"]:checked {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

input[type="checkbox"]:checked::after {
  content: "✓";
  color: white;
  font-size: 18px;
  display: block;
  text-align: center;
  line-height: 14px;
  font-weight: bold;
}

.icon.fa-trash {
  color: var(--secondary-color);
  height: var(--icon-height);

}

.editIcon {
  margin-right: 5px;
  color: var(--secondary-color);
  height: var(--icon-height);
}

.documentContainer {
  width: 250px;
  height: 300px;
}

.divImageContainer {
  padding-left: 10px;
  height: 100px;
}

.divFigureContainer {
  border: none;
  margin-bottom: 30px;
}

.imageFigure {
  height: 50px;
  margin-top: 10px;
}

.comments {
  width: 250px;
}

#dh_trash {
  height: 20px !important;
  width: 20px !important;
  padding-left: 15px;
  padding-right: 5px;
  cursor: pointer;
}

.divDetails {
  padding-left: 0px;
}

.figure-img {
  height: 20px;
  margin-bottom: 10px;
}

.rounded {
  margin-left: 0px;
}

.deleteIcon {
  left: 260px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--secondary-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 400px) {
  .alert-toast {
    right: 0.5rem;
    left: 0.5rem;
  }
}

.alert-content {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alert-icon {
  width: 24px;
  height: 24px;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.alert-close-icon {
  width: 20px;
  height: 20px;
}

.alert-text {
  flex: 1;
}

.alert-title {
  margin: 0 0 0.25rem 0;
  font-size: var(--primary-font);
  font-weight: 600;
}

.alert-message {
  margin: 0;
  font-size: var(--secondary-font);
  line-height: 1.5;
}

.alert-close {
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.alert-close:hover {
  opacity: 1;
}

.alert-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: fadeIn 0.2s ease-out;
}

.alert-modal {
  background: white;
  border-radius: 0.75rem;
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  animation: scaleIn 0.3s ease-out;
}

.alert-modal-content {
  text-align: center;
}

.alert-modal .alert-icon {
  width: 48px;
  height: 48px;
  margin: 0 auto 1rem;
}

.alert-modal-title {
  margin: 0 0 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.alert-modal-message {
  margin: 0 0 1.5rem 0;
  font-size: var(--primary-font);
  line-height: 1.5;
  color: #4b5563;
  max-width: 100%;
  overflow-wrap: break-word; 
}

.alert-modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  height: 50px;
}

.alert-modal-button {
  padding: 0.75rem 1.5rem;
  font-size: var(--primary-font);
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.alert-modal-button.cancel {
  background-color: var(--secondary-color);
  color: var(--primary-white)
}

.alert-modal-button.confirm {
  background-color: var(--secondary-color);
  color: white;
}

.alert-modal-success .alert-icon {
  color: #16a34a;
}

.alert-modal-error .alert-icon {
  color: #dc2626;
}

.alert-modal-info .alert-icon {
  color: #0284c7;
}

.alert-modal-warning .alert-icon {
  color: #ca8a04;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.alert-loader {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sidemenu-blurred {
  filter: blur(5px);
  pointer-events: none;
}

.main-div.blurred .sidemenu,
.main-div.blurred .child-div {
  filter: blur(5px);
}

.header.blurred {
  filter: blur(5px);
}

.header-logo-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 0;
  flex-grow: 1;
}

.header-logo-img {
  height: 24px;
  max-width: 100%;
  object-fit: contain;
  margin-right: 10px;
}

.notification-modal {
  position: fixed;
  top: 50px;
  right: 20px;
  max-width: 550px;
  max-height: 550px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

@media (max-width: 579px) {
  .notification-modal {
    left: 20px;
    width: 90%;
  }
}

.notification-close-btn {
  position: absolute;
  right: 10px;
  font-size: 24px;
  border: none;
  background: none;
  color: #2c3e50;
  cursor: pointer;
  z-index: 1000;
}

.notification-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.notification-header-title {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
  font-weight: bold;
  position: relative;
}

.notification-container {
  overflow-y: auto;
  max-height: 500px;
  padding-right: 10px;
}

.notification-container::-webkit-scrollbar {
  width: 6px;
}

.notification-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.notification-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.notification {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
  background-color: #ffffff;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.notification:hover {
  background-color: #f1f1f1;
  transform: scale(1.02);
}

.notification:last-child {
  border-bottom: none;
}

.notification-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.notification-title {
  font-weight: bold;
  font-size: 14px;
  color: #2c3e50;
}

.notification-time {
  font-size: 12px;
  color: #7f8c8d;
}

.notification-message {
  font-size: 14px;
  margin: 0;
  color: #2c3e50;
}

.notification-bell {
  border: 2.17px solid white;
  border-radius: 10px 10px 0 0;
  width: 15px;
  height: 17px;
  background: transparent;
  display: block;
  position: relative;
  top: -3px;
}

.notification-bell::before,
.notification-bell::after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2.17px;
}

.notification-bell::before {
  top: 100%;
  width: 20px;
}

.notification-bell::after {
  top: calc(100% + 4px);
  width: 7px;
}

.notification-badge {
  border: none;
  padding: 15px;
  border-radius: 50%;
  height: 30px;
  cursor: pointer;
  transition: 300ms;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.notification-badge-count {
  color: white;
  font-size: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #73859f;
  position: absolute;
  right: -11px;
  top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.addendum-modal.visible {
  display: flex;
}

.addendum-modal-content {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.addendum-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.addendum-modal-header h4 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.addendum-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
}

.addendum-close:hover {
  color: #000;
}

.addendum-modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.is-invalid {
  /* border-color: #dc3545 !important; */
}

.invalid-feedback {
  font-size: 0.875rem;
  color: #dc3545;
  margin-top: 0.25rem;
}

.addendum-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-top: 15px;
}

.addendum-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addendum-btn-primary {
  background-color: #6f7d95;
  color: white;
  border: none;
}

.addendum-btn-primary:hover {
  background-color: #5a6268;
}

.addendum-btn-secondary {
  background-color: #6f7d95;
  color: white;
  border: none;
}

.addendum-btn-secondary:hover {
  background-color: #5a6268;
}

@media (max-width: 768px) {
  .addendum-modal-content {
    width: 95%;
    max-width: 100%;
    padding: 15px;
  }

  .addendum-modal-header h4 {
    font-size: 1rem;
  }
}

.unique-form-group {
  margin-bottom: 1.5rem;
}

.unique-form-row {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  gap: 1rem;
}

.unique-form-label {
  font-weight: 400;
  white-space: nowrap;
}

.unique-form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.unique-form-control:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.unique-invalid-feedback {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #dc3545;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.unique-invalid-feedback i {
  font-size: 1rem;
  color: #dc3545;
}

.unique-invalid-feedback {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 579px) and (max-width: 992px) {
  .addendum-modal-content {
    margin: 15%;
  }
}

.field-selection-container {
  border-radius: 8px;
  margin: 0 auto;
}

.field-select-label,
.module-label,
.min-date-label,
.max-date-label,
.description-label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.field-select-dropdown,
.module-input,
.min-date-input,
.max-date-input,
.description-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.info-icon {
  margin-left: 5px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.form-group {
  flex: 1;
  min-width: 250px;
}

.center-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .field-selection-container {
    padding: 15px;
  }

  .form-row {
    flex-direction: column;
    gap: 12px;
  }
}

.fields-form-data-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (max-width: 1000px) {
  .fields-form-data-grid {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.fields-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.fields-module-name,
.fields-field-name,
.fields-checkbox-container {
  display: block;
}

.fields-label {
  margin-right: 5px;
}

.fields-checkbox-input {
  transform: scale(1.2);
}

.upload-color {
  margin-top: 8%;
  height: 10%;
  width: 10%;
  text-align: center;
}

@media (min-width: 1023px) {
  .header-logo {
    display: none;
  }
}

.details-title {
  margin-top: 5px;
  margin-bottom: 30px;
}

.customModal {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  inset: unset !important;
}

#approvalModal {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  margin-left: 100px;
}

.content {
  width: 500px;
  height: 280px;
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.commentBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 170px;
  padding: 15px;
}

.comment-header {
  color: var(--primary-white);
  margin-top: 5px;
}

.approval-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 4px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .modalCard {
    width: auto;
    height: auto;
    margin-right: 200px;
  }

  .header-bg {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

  }
}

@media screen and (max-width: 480px) {
  .content {
    width: 100%;
    height: auto;
  }

  .comment-header {
    font-size: 14px;
  }

  textarea {
    font-size: 14px;
  }

  .Buttons {
    flex-direction: column;
    gap: 5px;
  }
}

.success-message {
  color: var(--primary-white);
  font-size: 16px;
}

.selected-tebs {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
}

.badge {
  position: relative;
  width: fit-content !important;
  background-color: var(--secondary-color);
  color: white;
  border-radius: var(--primary-border-radius);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--secondary-font);
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px !important;
}

.docIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.docIcon {
  font-size: 65px;
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.document-item {
  flex: 1 1 23%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.form-input-container input,
.form-select,
.phone-input-container {
  width: 100%;
}

.input-font {
  font-size: 14px !important;
}

.adduser-button {
  padding: 15px;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.adduser_modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(29, 29, 29, 0.3);  justify-content: center;
  align-items: center;
  z-index: 2;
}

.adduser_modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 85%;
}

.add-user-header {
  padding: 5px !important;
}

.add-user-header2 {
  margin-top: 20px !important;
}

.add_user_btngrp {
  display: flex;
  justify-content: center;
  align-items: center;
}


.adduser-form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-top: 10px;
}

.adduser-form-label {
  font-size: 14px;
  font-weight: 500;
}

.adduser-form-input {
  width: 100%;
}

.adduser-input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  transition: border 0.3s ease-in-out;
  background-color: white;
}

.adduser-input-field:focus {
  outline: none;
}

.adduser-is-invalid {
  border-color: red;
}

.adduser-error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.adduser-phone-button {
  margin-right: 8px;
}

.adduser-phone-dropdown {
  max-height: 200px;
  overflow-y: auto;
}

@media (min-width: 576px) {
  .adduser-form-group {
    flex-direction: row;
    align-items: center;
  }

  .adduser-form-label {
    width: 30%;
    font-size: 16px;
  }

  .adduser-form-input {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .adduser-form-label {
    width: 25%;
  }

  .adduser-form-input {
    width: 75%;
  }
}

.dates-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dates-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

.dates-form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.dates-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dates-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.dates-select,
.dates-input,
.dates-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  transition: border 0.3s ease-in-out;
  background-color: white;
}

.dates-input:focus,
.dates-select:focus,
.dates-textarea:focus {
  outline: none;
}

.dates-icon {
  margin-left: 6px;
  color: #888;
  cursor: pointer;
}

.dates-button-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.dates-button {
  background-color: var(--secondary-color);
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.dates-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (min-width: 576px) {
  .dates-form-row {
    flex-direction: row;
  }

  .dates-form-group {
    width: 48%;
  }
}

@media (min-width: 768px) {

  .dates-form-group {
    width: 45%;
  }

  .dates-textarea {
    width: 100%;
  }
}

.fields-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fields-card {
  width: 100%;
  background: white;
  padding-top: 5px;
}

.fields-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

@media (min-width: 768px) {
  .fields-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 2560px) {
  .fields-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.fields-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background: #f9f9f9;
}

.fields-label-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 20%;
}

.fields-label {
  font-weight: 500;
  color: #333;
  margin-right: 8px;
}

.fields-value {
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.fields-checkbox-container {
  display: flex;
  align-items: center;
}

.fields-checkbox {
  transform: scale(1.2);
  cursor: pointer;
}

.fields-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.fields-button {
  padding: 10px 20px;
  background: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

@media (max-width: 425px) {

  .approval-responsive {
    padding: 10px;
  }

  .form-group.row {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-label {
    width: 100%;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .history-label-text {
    width: 58%;
  }

  .history-label-text .row {
    flex-direction: column;
  }

  .col-8,
  .col-4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }

  .cursor-pointer {
    display: flex;
    align-items: center;
  }

  .fa-download {
    margin-left: 5px;
  }
}
@media (max-width: 768px){
  .approveScroll {
    overflow-y: scroll; 
    max-height: 580px;
  }
}
.approveScroll::-webkit-scrollbar {
  display: none;
}

@media (max-width: 2560px) {
  .homeSelectBar {
    width: var(--xl-select-bar-width);
  }

  .homeSearchBar {
    width: var(--xl-input-bar-width);
  }
}

@media (max-width: 1024px) {
  .homeSelectBar {
    width: var(--large-select-bar-width);
  }

  .homeSearchBar {
    width: var(--large-input-bar-width);
  }
}

@media (max-width: 768px) {
  .homeSelectBar {
    width: var(--medium-select-bar-width);
  }

  .homeSearchBar {
    width: var(--medium-input-bar-width);
  }
}

@media (max-width: 425px) {
  .homeSelectBar {
    width: var(--small-select-bar-width);
  }

  .homeSearchBar {
    width: var(--small-input-bar-width);
  }
}

.forceopen-form-card-border {
  border: 1px solid #c4c4c4 !important;
  border-radius: var(--primary-border-radius);
  margin: 1.5rem !important;
  padding: .5rem !important;
}
.forceopen-form-card-no-border {
  margin: 3rem !important;
  padding: 1rem !important;
  display: none; /* Hide on screen */
}

@media print {
  .forceopen-form-card-no-border {
    display: block !important; /* Show only when printing */
  }
}

.button-loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.forceopen-form-inputType {
  background: #0000;
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  color: #3c4153;
  font-family: Poppins, sans-serif !important;
  font-size: 15px;
  outline: 0;
}

.forceopen-form-inputText {
  background: #0000;
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  font-size: 15px;
  outline: 0;
}

.forceopen-form-label {
  display: inline-block;
  font-family: Poppins, sans-serif !important;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.webcam {
  display: none;
}

.parentRadioDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  padding-bottom: 20px;
}

.radioOption {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkout-radio-label {
  margin: 0px;
}

@media (max-width: 992px) {
  .radioOption {
    flex: 1 1 45%;
  }
}

@media (max-width: 576px) {
  .radioOption {
    flex: 1 1 100%;
  }
}
.uploadIcons{
  display: flex;
  justify-content: flex-start;
  align-items: row;
  gap: var(--primary-gap);
}

.card-height{
  height: 90vh!important;
}

@media (max-width: 425px) {
  .card-height{
    height: 90vh!important;
  }
  
}

@media (min-height: 400px) and (max-height: 700px) {
  .right-section{
    top: 65%;
  }
}
@media (min-height: 701px) and (max-height: 900px) {
  .right-section{
    top: 60%;
  }
  
}
/* signin loader */

@media (min-width: 425px) and (max-width: 767px) {
  .signin-loader {
    top:75%;
  }
}

@media (max-width: 768px) {
  .signin-loader {
    top:80%;
  }
}

/* webcam styles */

@media (max-width: 425px) {
  video {
    height: 400px !important;
    width: 350px !important;
    aspect-ratio: 1 / 1 !important; 
    object-fit: cover !important; 
  }  
}

@media (max-width: 360px) {
  video {
    height: 300px !important;
    width: 300px !important;
    aspect-ratio: 1 / 1 !important; 
    object-fit: cover !important; 
  }  
}
.approval-container {
  min-height: 100vh; /* Ensures the container takes at least full viewport height */
}

/* add unit styles */

.warehouse-modal{
  backdrop-filter: blur(4px);
  background-color: rgba(230, 225, 225, 0.3);
}

.warehouse-modal .modal-dialog {
width: auto;
}

.unit-body{
  height: auto;
}
.unit-fields label {
  display: inline-block;
  width: 150px;
  font-weight: bold;
}

.align-form{
  display: flex;
}

.center-imageplus {
  align-items: center;
  background: #6f7d95;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 5px;
  width: 20px;
}
.plusIcon1 {
  color: #fff;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
}
.unit-label, input[type=text] {
  color: #3c4153;
  font-family: Poppins, sans-serif !important;
}

.history-section {
  max-height: 600px; 
  overflow-y: auto; 
  overflow-x: auto; 
  padding-right: 10px; 
}

.history-section::-webkit-scrollbar {
  display: none; 
}